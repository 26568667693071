@font-face {
  font-family: "custom-icon-pack";
  src: /*url(~@/assets/scss/fonts/custom-icon-pack.woff2) format("woff2"),*/ url(~@/assets/scss/fonts/custom-icon-pack.woff)
    format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'custom-icon-pack';
    src: url('../font/custom-icon-pack.svg?77519380#custom-icon-pack') format('svg');
  }
}
*/

[class^="cu-icon-"]:before,
[class*=" cu-icon-"]:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  // stylelint-disable-next-line
  font-family: "custom-icon-pack" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cu-icon-double-tick:before {
  content: "\e900";
} /* '' */
